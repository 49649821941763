/* Hide scrollbar for Chrome, Safari and Opera */
#breadcrumbs::-webkit-scrollbar {
  display: none;
}

#breadcrumbs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.breadcrumb.--active {
  color: #0173c6;
}

.breadcrumb {
  color: #041e41;
}

.--disabled g {
  fill: #d3d3d3;
}

.breadcrumb.--inactive {
  color: #d3d3d3;
}
