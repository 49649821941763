body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  padding-inline-start: 0;
}

article {
  box-shadow: none !important;
}

/* any screen size greater than 400px */
/* 
  this is to override the desgin systems default
  styles for tabbed cards
*/
@media only screen and (max-width: 25em) {
  article header {
    margin-bottom: 25px;
  }

  article header button {
    width: 100% !important;
    justify-content: start !important;
  }
}
